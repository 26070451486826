import { CoffeeSdkWrapper } from '@/api/coffeeApi/sdkWrapper.js';

class DexService extends CoffeeSdkWrapper {
  constructor() {
    super();
  }

  async getRoute(requestParams) {
    const {
      input_token,
      output_token,
      input_amount,
      output_amount,
      max_length,
      max_splits,
      additional_data
    } = requestParams;

    return await this.routingApi.buildRoute({
      input_token,
      output_token,
      input_amount,
      output_amount,
      max_length,
      max_splits,
      additional_data
    });
  }

  async getStakeTransaction(senderAddress, tokenAddress, amount, referralName) {
    const apiTonStakingTransactionRequest = {
      sender_address: senderAddress,
      token_address: tokenAddress,
      amount: amount,
      ...(referralName && { referral_name: referralName }),
    };

    return await this.routingApi.buildTonStakeTransaction(apiTonStakingTransactionRequest);
  }

  async getUnstakeTransaction(senderAddress, tokenAddress, amount) {
    const apiTonUnstakingTransactionRequest = {
      sender_address: senderAddress,
      token_address: tokenAddress,
      amount: amount,
    };

    return await this.routingApi.buildTonUnstakeTransaction(apiTonUnstakingTransactionRequest);
  }

  async getRouteTransactions(route, senderAddress, slippage, referralName) {
    const requestBody = {
      sender_address: senderAddress,
      slippage: slippage,
      paths: route.data?.paths || route.paths,
    };

    if (referralName) {
      requestBody.referral_name = referralName;
    }

    return await this.routingApi.buildTransactionsV2(requestBody);
  }

  async getTransactions(query_id) {
    return await this.routingApi.getRouteResult(query_id, {});
  }
}

export default new DexService();
