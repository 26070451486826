export default {
  state: () => ({
    token: null,
    points: null,
  }),
  mutations: {
    SET_SNOWFLAKE_TOKEN(state, value) {
      state.token = value;
    },
    SET_SNOWFLAKE_POINTS(state, value) {
      state.points = value;
    },
    RESET_SNOWFLAKE_POINTS(state) {
      state.points = 0;
    },
  },
  actions: {
    SAVE_SNOWFLAKE_TOKEN({ commit }, item) {
      commit('SET_SNOWFLAKE_TOKEN', item);
    },
    SAVE_SNOWFLAKE_POINTS({ commit }, item) {
      commit('SET_SNOWFLAKE_POINTS', item);
    },
    RESET_SNOWFLAKE_POINTS({ commit }) {
      commit('RESET_SNOWFLAKE_POINTS');
    },
  },
  getters: {
    GET_SNOWFLAKE_TOKEN: (state) => {
      return state.token;
    },
    GET_SNOWFLAKE_POINTS: (state) => {
      return state.points;
    },
  },
};